<template>
  <ab-flow-base-cmp class="checkbox-editor-cmp" :block="block" :class="checkClassesString" :style="stylesString">

    <div class="control" :class="{right: labelPosition !== 'left'}">
      <label v-if="label">{{label}}</label>

      <q-icon v-if="checkType === 'regular'" :name="currentIcon" @click="toggleCheck" :style="iconStyle"/>
      <template v-else>
        <img class="image-check"  :class="imageClass" :src="currentSource" @click="toggleCheck"/>
      </template>
    </div>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../renderMixins";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {formComponentsMixins} from "../Form/formComponentsMixins";
import {constants} from "../../constants";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "CheckBoxEditorCmp",
  data: () => ({
    test: false,
    targetVarMeta: false
  }),

  created() {
    // Get final var type
    this.targetVarMeta = this.getVarMeta(this.block.properties?.field)

    // Check for array
    if (this.targetVarMeta?.isArray && (this.currentValue === undefined || !Array.isArray(this.currentValue))) {

      // Init array
      this.currentValue = []
    }

  },

  methods: {

    /**
     * Toggle check
     */
    toggleCheck() {

      // Process array logic
      if (this.targetVarMeta?.isArray) {

        // Get index
        let index = this.currentValue.indexOf(this.checkedValue)

        // Check if value exists
        if (index > -1) {

          // Remove value
          this.currentValue.splice(index, 1)
        } else {

          // Add value
          this.currentValue.push(this.checkedValue)
        }
      } else {

        this.currentValue = this.currentValue != this.checkedValue ? this.checkedValue : false
      }

      // Process changed event
      //this.parentDiagram.processOutgoingLinks(this, this.block.id, this.currentValue, "changed")
    }
  },

  computed: {


    /**
     * Image style
     * @return {{"font-size": string}}
     */
    imageClass() {
      return this.getClasses({size: 1})
    },

    /**
     * Classes string
     * @return {*}
     */
    checkClassesString() {
      return this.getClasses(false, {size: 1})
    },

    /**
     * Current icon name
     * @return {*}
     */
    currentIcon() {

      // Check if array
      if (this.targetVarMeta?.isArray) {
        return this.currentValue.indexOf(this.checkedValue) > -1 ? this.checkedIcon : this.uncheckedIcon;
      } else {
        return this.currentValue == this.checkedValue ? this.checkedIcon : this.uncheckedIcon;
      }
    },

    /**
     * Current icon name
     * @return {*}
     */
    currentSource() {
      // Check if array
      if (this.targetVarMeta?.isArray) {
        return this.currentValue.indexOf(this.checkedValue) > -1 ? this.checkedImage :  this.uncheckedImage;
      } else {
        return this.currentValue == this.checkedValue ? this.checkedImage : this.uncheckedImage;
      }
    },

    /**
     * Icon style
     * @return {{"font-size": string}}
     */
    iconStyle() {
      return {'font-size': constants.sizes_to_pixels[this.block?.properties?.size || "md"] + "px"}
    },

    /**
     * Checkbox type
     * @return {*|string}
     */
    checkType() {
      return this.block?.properties?.type || "regular"
    },

    /**
     * Checked icon
     * @return {*|string}
     */
    checkedIcon() {
      return this.block?.properties?.checkedIcon || "check_box"
    },

    /**
     * Unchecked icon
     * @return {*|string}
     */
    uncheckedIcon() {
      return this.block?.properties?.uncheckedIcon || "check_box_outline_blank"
    },

    /**
     * Button label
     * @return {*|string}
     */
    label() {
      return this.interpretString(this.block.properties?.label);
    },

    /**
     * Label position
     * @return {string}
     */
    labelPosition() {
      return this.block.properties?.labelPosition || "right"
    },

    /**
     * Checked value
     * @return {*|string}
     */
    checkedValue() {
      return this.getValue(this.block.properties?.checkedValue) || true
    },

    /**
     * Checked image
     * @return {boolean|*|boolean}
     */
    checkedImage() {
      // Image path
      return this.renderer.a2u.assetPath(this.getValue(this.block?.properties?.checkedImage?.source_url)) || require("../../assets/plugs/default-image.png");
    },

    /**
     * Unchecked image
     * @return {boolean|*|boolean}
     */
    uncheckedImage() {
      // Image path
      return this.renderer.a2u.assetPath(this.getValue(this.block?.properties?.uncheckedImage?.source_url)) || require("../../assets/plugs/default-image.png");
    },
  }
}

</script>

<style lang="scss">

.checkbox-editor-cmp {
  .image-check {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .control {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      padding-right: 10px;
    }

    &.right {
      flex-direction: row-reverse;
      label { padding-left: 5px;}
    }
  }

}

</style>
