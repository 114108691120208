<template>

    <ab-flow-base-cmp :block="block">
        <q-banner v-if="generalValidationMessage" rounded class="bg-red text-white">
            {{generalValidationMessage}}
        </q-banner>

        <q-form class="column col" :class="classesString" :style="stylesString"
                ref="form"
                @submit="onSubmit"
                @reset="onReset"
        >
            <atu-components-renderer :items="block.children" />
        </q-form>

    </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "../../renderMixins";
import AtuComponentsRenderer from "../../../AtuComponentsRenderer";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";

export default {
    components: {AbFlowBaseCmp, AtuComponentsRenderer},
    mixins: [renderMixins],
    props: ['block'],
    name: "FormEditorCmp",

    /**
     * Provide
     * @returns {{parentForm: *}}
     */
    provide() {
        return {
            parentForm: this
        }
    },
    data() {
        return {
            values: {}
        }
    },

    /**
     * Set values
     */
    created() {

        // Set values
        /*this.block?.properties?.hidden_fields?.items?.map(el => {
            this.values[el.name] = this.execContent(el.value)
        })*/
    },

    computed: {

        /**
         * General validation results
         * @returns {any}
         */
        generalValidationMessage() {
            return this.block.validationResults?.message
        },
    },

    /**
     * Methods
     */
    methods: {

        /**
         * Submit form
         */
        submit() {
            this.$refs.form.submit()
        },

        /**
         * On submit
         * @param e
         */
        onSubmit() {
          //this.diagram.fireEvent('submit', this.block, this.values)
          this.parentDiagram.processOutgoingLinks(this, this.block.id, this.values,"submit")
        },

        /**
         * On reset
         * @param e
         */
        onReset() {
            //this.diagram.fireEvent('reset')
        }
    }
}

</script>
