import {BaseToolProcessor} from '../BaseToolProcessor';

/**
 * Code processor
 */
export class TextGeneratorProcessor extends BaseToolProcessor {
    toolName = 'ai:text-generator';

    /**
     * Process incoming event
     */
    async processEvent() {
        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                data: {},
                error: {message: `Error calling tool ${this.toolName}`},
            });
        }

        const text = this.context.interpretString(this.block?.properties?.text);
        const instruction = this.context.interpretString(this.block?.properties?.instruction);
        const bindings = this.block?.properties?.instruction?.outgoingBindings || {};

        if (!instruction) {
            this._debugLog('Missing properties: text, instruction');

            return;
        }

        try {
            // Call the tool
            const result = await this.context?.renderer?.a2u?.callTool(this.toolName, {
                text,
                instruction,
            }, true);

            // Save the bindings
            if (bindings && Object.keys(bindings).length) {
                for (const [variable, varToSave] of Object.entries(bindings)) {
                    if (!result[variable]) {
                        continue;
                    }

                    this.context.setOperationValue(varToSave, 'set', {
                        valueType: 'static',
                        value: result[variable],
                    });
                }
            }

            this.processOutgoingLinks(this.context, this.block.id, result, 'data');
        } catch (e) {
            console.error(`Error calling tool ${this.toolName}`, e);

            this._debugLog(e);

            this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }
}
