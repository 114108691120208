<template>
  <div
      class="fragment-editor-cmp"
      :class="[
      'fragment-'+fragmentType + ' '+classesString,
      {
        'fragment--safe-area': safeAreaInsets,
      }
    ]"
      :style="stylesString"
  >
    <atu-components-renderer :items="block.children"/>
  </div>
</template>

<script>

import AtuComponentsRenderer from "../../../AtuComponentsRenderer";
import {renderMixins} from "../../renderMixins";

export default {
  mixins: [renderMixins],
  components: {AtuComponentsRenderer},
  provide() {
    return {
      parentFragment: this,
      parentSafeAreaInsets: this.currentSafeAreaInsets || this.parentSafeAreaInsets || false,
    }
  },
  inject: {
    parentSafeAreaInsets: {
      default: false,
    }
  },
  props: {
    block: {},
  },
  name: "FragmentRendererCmp",
  data: () => ({
    stateKey: 0,
    storage: {},
    isShown: false,
  }),

  created() {
    // Check if fragment is modal
    if(this.fragmentType !== "modal") {
      this.isShown = true;

      // Hide loader if exists
      this.renderer.a2u.hideLoader();
    }

    // Check if the current diagram has debug app state
    if (this.renderer?.currentState && !this.parentWidget) {

      // Get debug params
      const params = this.renderer.currentState[this.getAppPath()];

      // If params are set, navigate to the diagram or fragment
      if (params?.fragmentStorage && this.getStorage("fragment")) {
        this.getStorage("fragment").storage = params.fragmentStorage;
        console.log("Set fragment storage", params.fragmentStorage, this.getStorage("fragment"))
      }
    }


    // Watch state
    this.$watch("stage", () => {
      console.log("FragmentRendererCmp state updated", this.block.id)
    })
  },

  /**
   * Before unmount event
   */
  beforeUnmount() {


    // Check if the current diagram has debug app state
    if (this.renderer?.currentState) {
      // Reset storage
      this.renderer.currentState[this.getAppPath()] = false;
    }

    // Call on unload event
    this.removeNavigateBackListeners();

  },

  computed: {
    stage() {
      return this.parentDiagram.processOutgoingLinks(this, this.block.id, false,"state")
    },

    /**
     * Determines if the safe area insets are disabled.
     *
     * @returns {boolean} True if the safe area insets are disabled, false otherwise.
     */
    disableSafeAreaInsets() {
      return this.block?.properties?.disableSafeAreaInsets === 1
    },

    /**
     * Determines if the fragment type requires safe area insets.
     *
     * @returns {boolean} True if the fragment type requires safe area insets, false otherwise.
     */
    currentSafeAreaInsets() {
      return ['page', 'sidebar'].includes(this.fragmentType) && !this.disableSafeAreaInsets;
    },

    /**
     * Computed property that determines if the safe area insets should be applied.
     *
     * This property returns true if the current fragment requires safe area insets and the parent fragment does not.
     * Safe area insets are used to avoid content being obscured by the device's status bar, notch, or other intrusions.
     *
     * @returns {boolean} True if the safe area insets should be applied, false otherwise.
     */
    safeAreaInsets() {
      return this.currentSafeAreaInsets && !this.parentSafeAreaInsets;
    },

    /**
     * This computed property is used to determine the type of the fragment.
     * The type of the fragment is retrieved from the `fragmentType` property of the block.
     * If the `fragmentType` property is not set, the default value 'page' is returned.
     *
     * @return {string} - Returns the type of the fragment.
     */
    fragmentType() {
      return this.block?.properties?.fragmentType || 'page';
    },
  },

  mounted() {

    // Call on load event
    this.onLoad();

    // Register "Navigation back" listeners
    this.addNavigateBackListeners();
  },
  methods: {

    /**
     * On load even
     */
    onLoad() {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, false,"create")
    },

    /**
     * Adds the navigate back listeners.
     */
    addNavigateBackListeners() {
      if (this.renderer.a2u.getDevice()?.getPlatform() === 'web') {
        return;
      }

      const App = this.renderer.a2u.getDevice()?.getPlugin?.("App");

      App.addListener('backButton', this.onNavigateBack);
    },

    /**
     * Removes the navigate back listeners.
     */
    removeNavigateBackListeners() {
      if (this.renderer.a2u.getDevice()?.getPlatform() === 'web') {
        return;
      }

      const App = this.renderer.a2u.getDevice()?.getPlugin?.("App");

      App.removeListener('backButton', this.onNavigateBack);
    },

    /**
     * Trigger the "navigate-back" event on the fragment.
     */
    onNavigateBack() {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, false, "navigate-back")
    },

  },
}

</script>

<style lang="scss">
.fragment-page {
  width: 100%;
}

.fragment-editor-cmp {
  display: flex;
  flex-direction: column;
  color: var(--foreground-color-primary);
  flex-grow: 1;

  &.fragment-sidebar {
    background: var(--background-color-primary);
    color: var(--foreground-color-primary);
    height: 100%;
    max-height: none;
  }

  &.fragment--safe-area {
    padding-top: var(--safe-area-top);
    padding-left: var(--safe-area-left);
    padding-right: var(--safe-area-right);
    //overflow: hidden;
    //height: calc(var(--screen-height, 100vh) - var(--keyboard-height, 0px));
    //min-height: calc(var(--screen-height, 100vh) - var(--keyboard-height, 0px));
    //max-height: calc(var(--screen-height, 100vh) - var(--keyboard-height, 0px));
  }
}

/*.fragment-scroll-area {
  height: 100%;

  & > .q-scrollarea__container {
    & > .q-scrollarea__content {
      display: flex;
      flex-direction: column;
    }
  }
}*/
</style>
