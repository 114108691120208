<template>

  <ab-flow-base-cmp :block="block" class="input-editor-cmp" :class="classesString" :style="stylesString">
    <q-input
        :label="inputLabel"
        :type="fieldType"
        :autogrow="isAutoGrow"
        @focus="onFocus"
        v-model="currentValue"
        @keydown="onKeyDown"
        @update:model-value="changed"
        :placeholder="inputPlaceholder"
        :dense="true"
        :hint="inputHint"
        :debounce="debounceMs"
        :maxlength="maxLength > 0 ? maxLength : undefined"
        class="full-width"
        :standout="standout"
        :rules="validationRules"
        :autofocus="autofocus"
        lazy-rules
        hide-bottom-space
        v-bind="dynamicProps"
    />
  </ab-flow-base-cmp>

</template>

<script>
import debounce from "lodash/debounce";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../renderMixins";
import {formComponentsMixins} from "./formComponentsMixins.js";
import validatableFormFieldMixin from './validatableFormFieldMixin';

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins, validatableFormFieldMixin],
  inject: {
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "InputEditorCmp",

  methods: {

    /**
     * On key down
     * @param event
     */
    onKeyDown(event) {
      if(this.block.properties?.keypress) {
        this.processKeyPresses(event)
      }
    },

    /**
     * On focus
     */
    onFocus() {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, undefined, 'focus')
    },

    /**
     * Value updated
     * @param event
     */
    changed: debounce(function () {
      //this.parentDiagram.processOutgoingLinks(this, this.block.id, event, "changed")
    }, 1000)
  },

  computed: {

    /**
     * Field type
     * @return {string}
     */
    fieldType() {
      // if password
      if (this.block.properties?.isPassword) {
        return 'password';
      }

      return this.block.properties?.isMultyLine ? "textarea" : "text"
    },

    /**
     * is autogrow
     * @return {*}
     */
    isAutoGrow() {
      return this.block.properties?.isAutoGrow ? true : false
    },


    /**
     * input label
     * @return {*|string}
     */
    inputLabel() {
      return this.interpretString(this.block.properties?.label);
    },

    /**
     * input placeholder
     * @return {*|string}
     */
    inputPlaceholder() {
      return this.interpretString(this.block.properties?.placeholder);
    },


    /**
     * input hint
     * @return {*|string}
     */
    inputHint() {
      return this.interpretString(this.block.properties?.hint);
    },

    /**
     * Returns the maximum length for the input field.
     *
     * @returns {number} The maximum length for the input field.
     */
    maxLength() {
      return parseInt(this.interpretString(this.block.properties?.maxLength)) || 0;
    },

    /**
     * Returns the debounce time in milliseconds for the input field.
     *
     * @returns {number} The debounce time in milliseconds.
     */
    debounceMs() {
      return parseInt(this.block.properties?.debounce) || 0;
    },

    /**
     * Determines the standout value for the block.
     * The standout value is parsed from the block's properties.
     * If the standout property is not set or is not a valid integer, it defaults to 0.
     * The result is then converted to a boolean value.
     * @return {boolean} The standout value for the block.
     */
    standout() {
      return !!(parseInt(this.block.properties?.standout) || 0);
    },

    /**
     * Determines if the input field should be auto-focused.
     *
     * @returns {boolean} True if the input field should be auto-focused, false otherwise.
     */
    autofocus() {
      return this.block.properties?.autofocus === 1;
    },
  }
}

</script>


<style lang="scss">

.input-editor-cmp {
  .q-field--standout .q-field__control {
    background: transparent !important;
    box-shadow: none !important;

    &:before {
      background: transparent !important;
    }
  }

  .q-field {
    .q-field__messages {
      color: var(--control-text-color);
    }

    &.q-field--error {
      .q-field__messages, .text-negative {
        color: var(--foreground-color-validation-error, #c10015) !important;
      }
    }

    .q-field__control {
      color: var(--control-focus-color, transparent);
      //height: 100%;
      //margin-left: 5px;
      //margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }

      &:before {
        border-color: currentColor !important;
      }
    }

    /*&.q-field--error {
      padding-bottom: 20px;

      .q-field__bottom {
       position: absolute;
      }
    }*/

    &:not(.q-field--focused) {
      .q-field__control {
        &:before {
          border-color: var(--control-unfocused-color, transparent) !important;
        }
      }
    }
  }
}


</style>
