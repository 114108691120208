import {nanoid} from "nanoid";
import {A2uAbOrm} from "a2u-renderer-common/src/utils/a2uAbOrm.js";
import {AbOrmVueQueryProcessor} from "ab-application/src/utils/AbOrmVueQueryProcessor";
import {AbOrmRemoteQueryProcessor} from "ab-application/src/utils/AbOrmRemoteQueryProcessor";
import {evalHelper} from "./evalHelper";
import {DbStorage} from './dbStorage';

// Allowed default values
const allowDefaultValue = [
    'secret',
    'string',
    'text',
    'int',
    'float',
    'bool',
    'datetime',
];

export class AbDatabase {


    /**
     * Constructor
     */
    constructor(a2u, app, isPersistent = false, customModels = {}) {
        this.config = {}
        this.models = {}
        this.customModels = customModels;
        this.app = app
        this.a2u = a2u
        this.currentSessionId = new Date().getTime()
        this.isPersistent = isPersistent
    }

    /**
     * Generate new id
     */
    generateId() {
        return (this.config?.prefixId ? this.config?.prefixId + "-" : "") + nanoid(16);
    }

    /**
     * Get model id by name
     * @param name
     */
    getModel(name) {
        return this.models[Object.values(this.config.tables).find(t => t.name === name)?.id]
    }

    /**
     * Evaluate query
     * @param query
     * @param params
     */
    async rawQuery(query, params = []) {
        // Get first model from models
        const model = Object.values(this.models)[0]

        // Eval
        return model.query().getRaw({query, params})
    }

    /**
     * Init db
     * @return {Promise<void>}
     */
    async init(db) {

        // Store config
        this.config = {
            db,
            tables: {
                ...this.config.tables,
                ...db.tables,
            }
        };

        // Initial data
        const initialData = {}

        // Process tables
        for (const table of Object.values(db.tables)) {

            // Skip undone config
            if (!table.fields?.length) continue;

            // Check if physical delete is enabled
            const physicalDelete = table?.physical_delete === 1 ? '\n physicalDelete = true;\n' : '';

            // Create model class
            const mClass = this.customModels[table.name] || evalHelper.evalInContext(`return (class ${db.name}${table.name} extends A2uAbOrm {${physicalDelete}})`, {A2uAbOrm});

            // Set model entity
            mClass.database = `${db.name}`
            mClass.entity = `${table.name}`

            // Construct primary key
            const primaryKey = table.fields.filter(f => f.key_field).map(f => f.name)

            // Check if no primary key - create simple id
            if (!primaryKey.length) {
                const idf = table.fields.find(f => f.name === 'id')
                if (idf) {
                    idf.key_field = true
                    primaryKey.push("id")
                }
            }

            // Store primary key
            mClass.primaryKey = primaryKey

            // Set original fields
            mClass.originalFields = Object.fromEntries(table.fields.map(f => {
                return [f.name, f.type]
            }))

            // Set converted fields
            mClass.fields = Object.fromEntries(table.fields.map(f => {
                return [f.name, this.convertA2UType(f.type)]
            }));

            // Set default values
            mClass.defaultValues = Object.fromEntries(
              table.fields.map((f) => {
                  if (allowDefaultValue.includes(f.type) && f.default_value) {
                      return [f.name, f.default_value];
                  }

                  return false;
              }).filter(Boolean)
            );

            // Set remove url to db server
            mClass.remoteUrl = this.a2u.source.modules[db.module_id]?.endpointUrl;
            mClass.moduleType = this.a2u.source.modules[db.module_id]?.type;
            //console.log(this.dbServerUrl)

            // Set model channels
            mClass.channels = Object.fromEntries(table.channels.map(c => {
                return [c.id, {
                    subscribe: () => c.channel_name,
                    init: async () => this.query(c.channel_query),
                }]
            }))

            // Set model primary key
            this.models[table.id] = mClass;
            initialData[table.name] = [...(table.content || []), ...(table.test_data || [])]
            //console.log("AbDatabase.init", table, mClass)

            // Setup models
            mClass.setup(this.app.client, new AbOrmVueQueryProcessor(this.app.client), new AbOrmRemoteQueryProcessor(this.app.client))
        }

        // Init local db
        if (!this.app.client.db.exists(db.name)) {

            // Get db adapter
            const dbAdapter = await this.getDbAdapter(db)

            // Init db
            if(!dbAdapter) {
                // Load db data
                const dbData = await this.loadDb(db.name)

                // Init sql.js db
                await this.app.client.db.get(db.name).init(
                    () => {
                        return dbData
                    },
                    (data, dbName) => {
                        return this.saveDb(data, dbName)
                    }, [], db.webWasmFile)
            } else {
                // Init db with adapter
                await this.app.client.db.get(db.name, dbAdapter)
            }
        }

        // Migrate all models
        for (const table of Object.values(db?.tables)) {

            // Get model by name
            const model = this.models?.[table.id]

            try {
                // Migrate data
                if (model) await this.migrate(model, initialData[model.entity], table?.is_static_data)
            } catch (ex) {
                console.error(ex)
            }
        }

        // Save db
        await this.app.client.db.get(db.name).save()
    }

    /**
     * Get db adapter
     * @return {string}
     */
    async getDbAdapter(db) {

        // No adapter for web
        if(this.a2u.getPlatform() === 'web') return null;

        // Get adapter
        const adapter = this.a2u.getDevice().getPlugin("DbAdapter")

        // Init adapter
        if(adapter) {
            return await new adapter(db).init();
        }
    }


    /**
     * Migrate model
     * @param model
     * @param initialData
     * @param isStaticData
     * @return {Promise<void>}
     */
    async migrate(model, initialData, isStaticData = false) {

        // If table exists - get schema
        const tableSchema = await this.getCurrentTableSchema(model)

        // Get model schema
        const modelSchema = this.getModelSchema(model)

        // Model schema has changed
        let schemaChanged = false;

        // If schemas are not equal - migrate
        if (JSON.stringify(tableSchema) !== JSON.stringify(modelSchema)) {

            // Log message
            console.log(`Making changes in table ${model.entity}`, JSON.stringify(tableSchema), " -> ", JSON.stringify(modelSchema))

            // Backup current table
            if (tableSchema) await this.app.client.db.get(model.database).query(`ALTER TABLE ${model.entity} RENAME TO ${model.entity}_${this.currentSessionId}`)

            // Create new table from modelSchema
            await this.app.client.db.get(model.database).query(`CREATE TABLE ${model.entity}
                                                                (  ` +
                Object.entries(modelSchema.fields).map(([name, type]) => {
                    let fieldSchema = ` ${type}`;

                    if (name === 'deleted') {
                        fieldSchema = " int DEFAULT 0 NOT NULL";
                    } else if (modelSchema?.defaultValues?.[name]) {
                        fieldSchema = ` ${type} DEFAULT ${modelSchema.defaultValues[name]}`;
                    }

                    return `  \`${name}\` ${fieldSchema}`
                }).join(",\n") +

                (modelSchema.indexes.PRIMARY ? ",\n  PRIMARY KEY (" + modelSchema.indexes.PRIMARY.fields.join(", ") + ")" : "") +

                Object.entries(modelSchema.indexes).filter(([name,]) => name !== 'PRIMARY').map(([name, index]) => {
                    return `,\n  ${index.type} ${name} (${index.fields.join(", ")})`
                }).join("") +
                "\n)") /**/

            // Set schema changed
            schemaChanged = true;

            // Copy data from backup
            if (tableSchema) {
                const commonFields = Object.keys(modelSchema.fields).filter(f => Object.keys(tableSchema.fields).includes(f));

                if (commonFields.length) {
                    try {
                        // Trying to migrate data
                        await this.app.client.db.get(model.database).query(`INSERT INTO ${model.entity} (${commonFields.join(", ")})
                                                                        SELECT ${commonFields.join(", ")}
                                                                        FROM ${model.entity}_${this.currentSessionId}`)

                    } catch (ex) {
                        console.error(ex)
                    }
                }
            }
        }

        // Insert initial data
        if (isStaticData || schemaChanged) {
            try {
                // Save current time
                const stTime = new Date().getTime();

                // Get initial data
                let insertData = Object.values(
                  (initialData || []).filter((row) => !!row?.id).reduce((res, row) => {
                      // Check if row already exists
                      if (res[row.id]) {
                          return res;
                      }

                      // Store row
                      res[row.id] = row;

                      return res;
                  }, {}),
                );

                // Check if rows already exists
                const existsRows = (await model.query().getRaw({
                    query: `SELECT \`id\` FROM ${model.entity} WHERE \`id\` in (${Array(insertData.length).fill("?").join(', ')})`,
                    params: insertData.map(row => row.id),
                }) || []).map(row => `${row.id}`);

                // Filter rows
                insertData = insertData.filter(row => !existsRows.includes(`${row.id}`));

                // Insert data into table if exists
                if (insertData.length) {
                    // Get table fields
                    const fields = Object.keys(model.fields).filter((field) => field !== 'deleted');

                    // Insert values
                    const values = insertData.map(row => {
                        row = model.serialize(Object.fromEntries(Object.entries(row)));

                        return `(0, ${fields.map(
                          (field) => this.prepareValue(row[field], model.fields[field])
                        ).join(", ")})`
                    }).join(", ");

                    // Insert data
                    await this.app.client.db.get(model.database).query(
                      `INSERT INTO ${model.entity} ( deleted, ${fields.join(", ")}) VALUES ${values}`
                    );

                    // log
                    console.log(`Initial data inserted into table ${model.entity}, rows: ` + insertData.length + ", insert time: " + (new Date().getTime() - stTime) + "ms")
                }
            } catch (e) {
                console.error(`Error inserting initial data into table ${model.entity}`, e);
            }
        }
    }

    /**
     * Prepare value to insert
     * @param value
     * @param type
     */
    prepareValue(value, type) {

        // If value is null - return null
        if (value === undefined || value === null) return "null"

        switch (type) {
            default:
                return typeof value === 'string' ? `'${value.replace(/'/g, "''")}'` : value
        }
    }

    /**
     * Get model schema
     * @param model
     * @return {{indexes: *, name: string, fields: ([]|*)}}
     */
    getModelSchema(model) {

        // Convert db types
        const dbTypes = {
            "autogenerated": "string",
            "autoincrement": "int",
        }

        // Store scheme in comparable format
        return {
            fields: Object.assign({
                deleted: "int",
            }, Object.fromEntries(Object.entries(model.fields).map(([name, type]) => {
                return [name, dbTypes[type] || type]
            }))),
            indexes: model.primaryKey?.length ? {PRIMARY: {type: "PRIMARY", fields: model.primaryKey}} : {},
            defaultValues: Object.fromEntries(
              Object.entries(model.defaultValues || {}).map(([name, value]) => {
                  return [name, this.prepareValue(value)]
              })
            ),
        };
    }

    /**
     * Get table schema
     * @return {Promise<void>}
     */
    async getCurrentTableSchema(model) {

        // Check if table exists
        const tableExists = (await this.app.client.db.get(model.database).query(`SELECT name
                                                                                 FROM sqlite_master
                                                                                 WHERE name = '${model.entity}'
                                                                                   and type = 'table'`))?.[0]?.name;

        // Return false if table not exists
        if (!tableExists) return false

        // Store scheme in comparable format
        const schema = {
            fields: {},
            indexes: {},
        };

        // Get schema
        const fields = await this.app.client.db.get(model.database).query(`PRAGMA table_info(${model.entity});`);

        // Fields list
        schema.fields = Object.fromEntries(fields.map(f => {

            // Find model field type
            //const modelFieldType = Object.entries(Object.assign({deleted: "int"}, model.fields)).find(([name, ]) => name === f.Field)?.[1]

            // Convert type
            return [f.name, f.type.toLowerCase()]
        }));

        // Get indexes
        const indexes = await this.app.client.db.get(model.database).query(`pragma index_list(${model.entity})`);

        // Indexes list
        schema.indexes = {}

        // Process each index
        for (const i of indexes) {

            // Get index name
            const iName = i.origin === 'pk' ? 'PRIMARY' : i.name

            // Get index detals
            const fields = await this.app.client.db.get(model.database).query(`pragma index_info(${i.name})`);

            // Init index
            if (!schema.indexes[iName]) schema.indexes[iName] = {
                type: i.origin === 'pk' ? 'PRIMARY' : !i.unique ? 'INDEX' : 'UNIQUE',
                fields: []
            };

            // Add field
            schema.indexes[iName].fields.push(...fields.map(f => f.name))
        }

        // Set default values
        schema.defaultValues = Object.fromEntries(
          fields.map((f) => {
              if (f.dflt_value && allowDefaultValue.includes(model.fields?.[f.name]) && !['null', 'undefined'].includes(f.dflt_value)) {
                  return [f.name, f.dflt_value];
              }

              return false;
          }).filter(Boolean)
        );

        // Return result
        return schema;
    }

    /**
     * Convert A2U type
     * @param type
     * @return {*|string}
     */
    convertA2UType(type) {
        switch (type) {
            case 'file':
            case 'localizedString':
                /*case 'image':
                case 'sound':
                case 'lottie':*/
                return 'json'
            case 'autoincrement':
                return 'int'
            default:
                return type
        }
    }

    /**
     * Load local db
     * @param dbName
     * @return {Uint8Array|boolean}
     */
    async loadDb(dbName) {
        if (this.isPersistent) {

            // Data
            let dta = false;

            // Try to load a file from file system
            try {
                const fs = this.a2u.getPlatform() !== 'web' ? this.a2u.getDevice().getPlugin("FileSystem") : false;
                if (fs) {
                    dta = await this.a2u.getDevice().getPlugin("FileSystem").readFile(`db-${dbName}`, null);
                }
            } catch (ex) {
                console.error(ex)
            }

            // Try to load from local storage
            if (!dta && this.a2u.getPlatform() === 'web') {
                dta = await DbStorage.getDb(`db-${dbName}`);
            }
            return dta ? new Uint8Array(atob(dta).split('').map(char => char.charCodeAt(0))) : false;
        }
    }

    /**
     * Convert buffer to base64
     * @param buffer
     * @return {Promise<*>}
     */
    async bufferToBase64(buffer) {
        const base64url = await new Promise(r => {
            const reader = new FileReader()
            reader.onload = () => r(reader.result)
            reader.readAsDataURL(new Blob([buffer]))
        });
        // remove the `data:...;base64,` part from the start
        return base64url.slice(base64url.indexOf(',') + 1);
    }

    /**
     * Save local db
     * @param dbName
     * @param data
     */
    async saveDb(data, dbName) {
        if (this.isPersistent) {

            // Saved
            let saved = false;

            // example use:
            const bData = await this.bufferToBase64(data)

            // Convert to base64
            //const bData = btoa(String.fromCharCode.apply(null, data));

            // Try to save a file to file system
            try {
                const fs = this.a2u.getPlatform() !== 'web' ? this.a2u.getDevice().getPlugin("FileSystem") : false;
                if (fs) {
                    await this.a2u.getDevice().getPlugin("FileSystem").writeFile(`db-${dbName}`, bData, null);
                    saved = true;
                }
            } catch (ex) {
                console.error(ex)
            }

            if (!saved && this.a2u.getPlatform() === 'web') {
                await DbStorage.saveDb(`db-${dbName}`, bData);
            }
        }
    }

    /**
     * Subscribe to data channel
     * @param tableId
     * @param channel
     * @param params
     * @return {Promise<*>}
     */
    async subscribe(tableId, channel, params = {}) {

        // No subscriptions in debug mode
        if (!this.models[tableId]?.remoteUrl) {
            console.warn("No subscriptions for local tables");
            return;
        }

        // Subscribe
        return this.models[tableId].remote().subscribe(channel, params);
    }

    /**
     * Unsubscribe from data channel
     * @param tableId
     * @param channel
     */
    unsubscribe(tableId, channel) {
        console.log("AbDatabase.unsubscribe", tableId, channel)
    }

    /**
     * Query data
     * @param tableId
     * @param query
     * @param params
     * @return {Promise<*|PermissionStatus|LockManagerSnapshot>}
     */
    async queryRaw(tableId, query, params = []) {
        return this.models[tableId].query().getRaw({query, params})
    }

    /**
     * Query data
     * @param tableId
     * @param query
     * @param params
     * @return {Promise<*|PermissionStatus|LockManagerSnapshot>}
     */
    async query(tableId, query, params = []) {
        return this.models[tableId].query().get({query, params})
    }

    /**
     * Delete item
     * @param tableId
     * @param whereObj
     * @return {Promise<void>}
     */
    async delete(tableId, whereObj) {

        // Get model
        const model = this.models[tableId];

        if (model.moduleType === 'server' && model?.remoteUrl) {
            return model.remote().delete(whereObj);
        } else {
            return model.delete(whereObj)
        }
    }

    /**
     * Save data to db
     * @param tableId
     * @param row
     * @return {Promise<void>}
     */
    async save(tableId, row) {

        // No result by default
        let res = false;

        // Get model
        const model = this.models[tableId];

        // Delete function
        if (model.moduleType === 'server' && model?.remoteUrl) {
            res = await model.remote().save(row)
        } else {
            // Save row locally
            res = await model.save(this.prepareData(model, row))
        }

        // Return row
        return res;
    }

    /**
     * Prepare fields
     * @param model
     * @param data
     */
    prepareData(model, data) {

        // Process each field
        for (const [field, type] of Object.entries(model.originalFields || {})) {
            switch (type) {
                case 'autoincrement':

                    // Local sql doesn't support autoincrement fields
                    // We need to generate id manually for testing purposes
                    if (this.a2u.getBuildTarget() !== 'server' && model.moduleType === 'server' && !model?.remoteUrl) {
                        if (data[field] === undefined) data[field] = Math.ceil(Math.random() * 1000000)
                    }
                    break;
            }
        }

        // Return data
        return data;
    }
}
