import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class DiagramComponentProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Check if fragment belongs to container
     * @returns {Object}
     */
    getParentContainer() {

        // Get parent container
        for(const ev of Object.values(this.getDiagram().links?.getIncomingLinks(this.block) || {}) ) {
            const cnt = ev.find(ev => ev.action === 'children')
            if(cnt) return this.getDiagram().getBlockById(cnt.source)
        }
    }

    /**
     * Process incoming event
     * @param event
     * @param data
     * @param linkId
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data, linkId) {
        // Params object
        /*const params = {}

        // Prepare params if set
        for(const [name, value] of Object.entries(this.block?.properties?.arguments || {})) {
            // Set param
            params[name] = this.context.getValue(value)
        }*/

        //console.error('DiagramComponentProcessor.processEvent', event, data, params, this.block, this.block?.properties?.arguments);

        // Get source teleport id
        const sourceTeleportId = this.app.renderer.a2u.links.incoming[this.block.id]?.start?.find(e => e.source_event === 'children')?.source;

        // Get diagram id
        const diagramId = this.block.properties?.diagramComponentId;

        // Get diagram type
        const diagramType = this.app?.renderer?.a2u?.diagrams[diagramId]?.diagram_type || null;

        // Send event for teleport to switch context if source teleport is set
        if(sourceTeleportId) {

            // Get parent fragment
            for(const pId of this.context.renderer.a2u.blocks[sourceTeleportId]?.parents || []) {
                // Load block
                if(this.context.renderer.a2u.blocks[pId]?.node?.type === 'Fragment') {
                    // Set teleport navigation
                    this.context.currentDiagram.getStorage("diagram").set(`teleports.${sourceTeleportId}`, {blockId: this.block.id, event});

                    // Navigate to parent fragment first
                    this.context.currentDiagram.navigate(pId, undefined, undefined, linkId);
                }
            }
        } else if(diagramType === 'process') {
            // Execute process diagram
            this.context.currentDiagram?.execProcessDiagram(this.block);
        } else {
            if (this.block.properties?.isModalDialog === 1) {
                this.context.currentDiagram?.showModal(this.block.id, {
                    type: "modal",
                    isPersistent: this.block?.properties?.isPersistent ? true :undefined,
                    noBackdrop: this.block?.properties?.noBackdrop === 1 ? true :undefined,
                    position: this.block?.properties?.modalPosition || "center",
                    modalWidth: this.block?.properties?.modalWidth || "auto",
                    transitionShow: this.block?.properties?.transitionShow || 'fade',
                    transitionHide: this.block?.properties?.transitionHide || 'fade',
                    transitionDuration: this.block?.properties?.transitionDuration || 300,
                    overlayColor: this.block?.properties?.overlayColor || undefined,
                });

                return;
            }

            // Reset modals
            this.app.hideModals();
            this.app.navigate(this.block.id, data?.input, event, linkId);
        }
    }
}
