import {reactive} from "vue";
import moment from "moment";
import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Waits for a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to wait.
 * @returns {Promise<void>} - A promise that resolves after the specified time.
 */
const wait = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Generates a random integer between the specified minimum and maximum values, inclusive.
 *
 * @param {number} min - The minimum value (inclusive).
 * @param {number} max - The maximum value (inclusive).
 * @returns {number} - A random integer between min and max.
 */
const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * ChatProcessor class for handling chat messages.
 */
export class ChatProcessor extends BaseComponentProcessor {

  // Define the static property to store the chat statuses
  static currentChatStatuses = reactive({});

  /**
   * Splits a string by punctuation marks and handles emojis.
   *
   * @param {string} str - The string to split.
   * @returns {string[]} - An array of strings split by punctuation and merged with emojis.
   */
  static splitByPunctuation(str) {
    const result = [];
    const punctuation = /[.!?]/;

    let i = 0;
    while (i < str.length) {
      const match = str.slice(i).match(punctuation);
      if (!match) {
        result.push(str.slice(i).trim());
        break;
      }

      const punctIndex = match.index;
      const endIndex = i + punctIndex + 1;

      let nextSpaceIndex = str.slice(endIndex).search(/\s/);
      if (nextSpaceIndex === -1) nextSpaceIndex = str.length - endIndex;

      result.push(str.slice(i, endIndex + nextSpaceIndex).trim());

      i = endIndex + nextSpaceIndex;
    }

    const emojiRegex = /^\p{Emoji}/u;

    // Merge emoji with the previous part
    return result.reduce((acc, str, idx) => {
      if (emojiRegex.test(str) && idx > 0) {
        acc[acc.length - 1] += ' ' + str;
      } else {
        acc.push(str);
      }

      return acc;
    }, []);
  }

  /**
   * Init component
   * @return {Promise<void>}
   */
  static async init(blockId) {

    // Get the block data
    const blockData = this?.blocks[blockId]?.node;

    // Retrieve the database
    const db = this?.dbs[blockData?.properties?.dbId];

    // Retrieve the tableId and the fields of the table
    const tableId = blockData?.properties?.tableId;

    // Set the dbModel property to the model of the table in the database
    const dbModel = db?.models[tableId];

    // Subscribing to the chat channel
    this.socketListener.on('ab-chat:answer', async (data) => {
      // Check if the message is split
      const splitMessage = blockData?.properties?.splitMessage === 1;

      // Check message type
      if (data.metadata?.type === "status") {
        if (!data.metadata.status && splitMessage) {
          return;
        }

        // Set chat status by id
        ChatProcessor.currentChatStatuses[data.chat_id] = data.metadata.status;

        // Stop here
        return;
      }

      if (splitMessage) {
        // Split the message by punctuation
        const answerParts = ChatProcessor.splitByPunctuation(data.message);

        // Get the sent time
        const sentAt = moment(data.sent_at * 1000);
        let seconds = 0;

        // Set the chat status to typing
        ChatProcessor.currentChatStatuses[data.chat_id] = 'typing';

        // Save the parts of the message
        for (const [idx, part] of answerParts.entries()) {
          if (idx > 0) {
            const delay = getRandomNumber(1, Math.max(2, blockData?.properties?.splitMessageTimeout || 3));
            seconds += delay;

            await wait(delay * 1000);
          }

          // Calculate the received time
          const received_at = sentAt.clone().add(seconds, 'seconds').unix();

          // Save to local db
          await dbModel.save({
            chat_id: data.chat_id,
            type: "incoming",
            message: part,
            metadata: data.metadata,
            options: data.options,
            sent_at: received_at
          });
        }

        // Set the chat status to null
        ChatProcessor.currentChatStatuses[data.chat_id] = null;
      } else {
        // Save to local db
        await dbModel.save({
          chat_id: data.chat_id,
          type: "incoming",
          message: data.message,
          metadata: data.metadata,
          options: data.options,
          sent_at: data.sent_at
        });
      }
    });
  }

  /**
   * Processes the event for the chat message.
   * @param {String} event - The event to process.
   * @param {Object} data
   * @returns {Promise} The result of the event processing.
   */
  async processEvent(event, data) {
    // Get the call handler function
    const callHandlerFn = this?.context?.currentDiagram?.callHandler || this?.context?.callHandler;

    // If the call handler function is not a function, return
    if (typeof callHandlerFn !== 'function') {
      return;
    }

    // Call handler
    callHandlerFn(this.block.id, 'incomingEvent', {event, data: data?.input || data});
  }
}
