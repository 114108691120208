/**
 * DebugLogger class
 * This class is used to log debug information. It stores the logged events in an array.
 */
export class DebugLogger {
  /**
   * Constructor for the DebugLogger class.
   * Initializes an empty array of events.
   */
  constructor() {
    this.events = [];
  }

  /**
   * Logs a debug event and stores it in the events array.
   * @param {Object} event - The event to log. It should have a type, a message, and data.
   * @param {'flow-start'|'flow-finish'|'error'|'warning'} event.type - The type of the event.
   * @param {string} event.message - The message of the event.
   * @param {any} event.data - The data of the event. It can be of any type.
   */
  log({type, message, data}) {
    if (type === 'error') {
      console.error(`Error: ${message} \nCheck the log in the debug console for details`);
    }

    this.events.push({type, message, data, time: new Date()});
  }

  /**
   * Clears all logged events from the DebugLogger.
   */
  clear() {
    this.events = [];
  }
}
