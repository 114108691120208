import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Fragment processor
 */
export class FragmentProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     * @param linkId
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data, linkId) {
        // Params object
        const params = {}

        // Prepare params if set
        for(const [name, value] of Object.entries(this.block?.properties?.arguments || {})) {
            // Set param
            params[name] = this.context.getValue(value)
        }

        // Get source teleport id
        const sourceTeleportIds = this.app.renderer.a2u.links.incoming[this.block.id]?.navigate?.filter(e => e.source_event === 'children')?.filter(e => {
            return !!this.context.renderer.a2u.blocks[e.source]
        }).map(e => e.source);

        // Process teleports
        for(const sourceTeleportId of sourceTeleportIds || []) {

            // Skip not existing blocks
            if(!this.app.renderer.a2u.blocks[sourceTeleportId]) continue;

            // Send event for teleport to switch context if source teleport is set
            //this.context.parentDiagram.callHandler(sourceTeleportId, "navigate", {blockId: this.block.id, params, event})
            // Get parent fragment
            for(const pId of this.context.renderer.a2u.blocks[sourceTeleportId]?.parents || []) {
                // Load block
                if(this.context.renderer.a2u.blocks[pId]?.node?.type === 'Fragment') {
                    // Set teleport navigation
                    this.context.currentDiagram.getStorage("diagram").set(`teleports.${sourceTeleportId}`, {blockId: this.block.id, event});

                    // Navigate to parent fragment first
                    this.context.currentDiagram.navigate(pId, undefined, undefined, linkId);
                }
            }
        }
        // Send event for teleport to switch context if source teleport is set
        if(sourceTeleportIds?.length) return;

        //console.log('FragmentProcessor.processEvent', event, data, params);
        switch (event) {

            // Navigate to fragment
            case 'navigate':

                // Check for sidebar fragment type
                if (this.block.properties?.fragmentType === 'sidebar') {
                    this.context.currentDiagram?.showModal(this.block.id, {
                        type: "sidebar",
                        position: "left",
                        fullHeight: true
                    });
                    break;
                }

                // Check for fragment type
                if (this.block.properties?.fragmentType === 'modal') {
                    this.context.currentDiagram?.showModal(this.block.id, {
                        type: "modal",
                        isPersistent: this.block?.properties?.isPersistent ? true :undefined,
                        noBackdrop: this.block?.properties?.noBackdrop === 1 ? true :undefined,
                        modalWidth: this.block?.properties?.modalWidth || "auto",
                        position: this.block?.properties?.modalPosition || "center",
                        transitionShow: this.block?.properties?.transitionShow || 'fade',
                        transitionHide: this.block?.properties?.transitionHide || 'fade',
                        transitionDuration: this.block?.properties?.transitionDuration || 300,
                        overlayColor: this.block?.properties?.overlayColor || undefined,
                    });
                } else {

                    // Reset modals
                    this.context.currentDiagram?.hideModals?.();

                    // Navigate
                    this.app.navigate(this.block.id, params, event, linkId);
                }
                break;
        }
    }
}
