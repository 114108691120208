import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class ForEachProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    async processEvent(event, data) {

        // Get items
        const items = this.context.getValue(this.block.properties?.dataSource, undefined, data?.input);

        if (!Array.isArray(items)) {
            console.error('ForEach: items is not an array');
            return;
        }

        // Process items
        for (const item of items) {
            await this.processOutgoingLinks(this.context, this.block.id, item, 'item');
        }

        // Process completed
        return this.processOutgoingLinks(this.context, this.block.id, null, 'completed');
    }
}
