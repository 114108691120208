/**
 * VoiceChatService
 */
export class VoiceChatService {
    constructor(sendCallback) {
        this.sendCallback = sendCallback;
        this.mediaRecorder = null;
        this.stream = null;
        this.isRecording = false;
        this.mimeType = null;
        this.recordedBlobs = [];
    }

    async checkMicrophoneAccess() {
        try {
            // Check if the browser supports getUserMedia
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                throw new Error('getUserMedia is not supported in this browser');
            }

            // Request microphone access
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // If we get here, permission was granted
            // Stop the stream immediately as we don't need it yet
            stream.getTracks().forEach(track => track.stop());

            console.log('Microphone access granted');
            return true;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            return false;
        }
    }

    async init() {
        const hasAccess = await this.checkMicrophoneAccess();
        if (!hasAccess) {
            console.error('Microphone access denied. Cannot initialize.');
            return;
        }

        try {
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Check for supported MIME types
            if (MediaRecorder.isTypeSupported('audio/webm')) {
                this.mimeType = 'audio/webm';
            } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
                this.mimeType = 'audio/ogg';
            } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                this.mimeType = 'audio/mp4';
            } else {
                console.warn('No supported MIME types found. Using default.');
                this.mimeType = '';
            }

            const options = this.mimeType ? { mimeType: this.mimeType } : undefined;
            this.mediaRecorder = new MediaRecorder(this.stream, options);

            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    this.recordedBlobs.push(event.data);
                }
            };

            this.mediaRecorder.onstop = () => {
                const blob = new Blob(this.recordedBlobs, { type: this.mimeType || 'audio/webm' });
                this.sendAudioToServer(blob);
            };

            console.log('Microphone initialized');
        } catch (error) {
            console.error('Error initializing microphone:', error);
        }
    }

    destroy() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
        this.mediaRecorder = null;
        this.stream = null;
        this.mimeType = null;
        this.recordedBlobs = [];
        console.log('Resources released.');
    }

    start() {
        if (!this.mediaRecorder) {
            console.error('MediaRecorder not initialized. Call init() first.');
            return;
        }

        this.recordedBlobs = [];
        this.isRecording = true;
        this.mediaRecorder.start();
        console.log(`Recording started with MIME type: ${this.mimeType || 'default'}`);
    }

    stop() {
        if (!this.isRecording) return;

        this.isRecording = false;
        this.mediaRecorder.stop();
        console.log('Recording stopped');
    }

    async sendAudioToServer(audioBlob) {
        const formData = new FormData();
        const fileExtension = this.mimeType ? this.mimeType.split('/')[1] : 'webm';
        formData.append('audio', audioBlob, `audio.${fileExtension}`);
        console.log('Sending audio to server:', audioBlob);

        await this.sendCallback(audioBlob);
    }
}
