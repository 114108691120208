<template>

  <ab-flow-base-cmp class="navigation-panel row" :block="block" :class="classesString" :style="stylesString">
    <q-tabs v-model="tabValue" class="col" no-caps  :switch-indicator="selPosition" @update:model-value="clickButton" :vertical="vertical" :inline-label="vertical" >
      <template v-for="(item, i) of finalItems" :key="i">
        <q-tab :icon="getIcon(item)" :name="item.value" @click="onClickTab(item.value)">
          <label>{{ item.title }}</label>
        </q-tab>
      </template>
    </q-tabs>
  </ab-flow-base-cmp>

</template>

<script>
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../components/renderMixins";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "NavigationPanelEditorCmp",
  data: () => ({
    tab: "",
    defaultTabValue: "",
  }),
  computed: {

    /**
     * Current form control value
     */
    tabValue: {
      get: function () {

        // No var set
        if(!this.block?.properties?.field) return this.defaultTabValue;

        // Check for form values or regular model
        const res = this.getValue(this.block?.properties?.field);
        return res === undefined || res === null ? this.defaultTabValue : res;
      },
      // setter
      set: function (newVal) {
        // No var set
        if(!this.block?.properties?.field) {
          this.defaultTabValue = newVal;
          return
        }

        // Set value
        this.setOperationValue(this.block?.properties?.field, 'set', newVal);
      }
    },

    /**
     * If vertical direction
     * @return {boolean}
     */
    vertical() {
      return ['left', 'right'].includes(this.block?.properties?.position)
    },

    /**
     * If up position
     * @return {boolean}
     */
    selPosition() {
      return ['right', 'down'].includes(this.block?.properties?.position)
    },

    /**
     * Items list
     * @return {*}
     */
    itemsList() {
      return (this.block?.properties?.items?.items || []).map((item) => ({
        ...item,
        title: this.interpretString(item.title),
      }));
    },

    /**
     * This computed property is used to get the dynamic items from the block properties.
     * It first retrieves the value of the dynamicItems property from the block properties.
     * If the retrieved value is not an array, it wraps the value in an array.
     * It then filters the items, ensuring each item is an object and contains all the required keys.
     * The required keys are 'title', 'icon', 'image', and 'value'.
     * @return {Array} An array of items that are objects and contain all the required keys.
     */
    dynamicItems() {
      // Get the value of the dynamicItems property from the block properties.
      let items = this.getValue(this.block?.properties?.dynamicItems, []);

      // If the retrieved value is not an array, wrap it in an array.
      if (!Array.isArray(items)) {
        items = [items];
      }

      // Filter the items, ensuring each item is an object and contains all the required keys.
      return items.filter(
        (item) => {

          // Check if item is object
          if(!item || typeof item !== 'object' ) return false;

          // Get required fields
          item.title = item.title || item.label || item.name || "No Name"
          item.value = item.value || item.id || "No value"

          // Return if title and value
          return item.title && item.value
        }
      );
    },

    /**
     * This computed property is used to get the final list of items.
     * It combines the items from the itemsList and dynamicItems computed properties.
     * The spread operator (...) is used to create a new array that includes all the items from both properties.
     * @return {Array} An array that includes all the items from the itemsList and dynamicItems computed properties.
     */
    finalItems() {
      return [
        ...this.itemsList,
        ...this.dynamicItems,
      ];
    },

    /**
     * Button color
     * @return {string}
     */
    buttonColor() {
      return this.block?.properties?.color || "";
    },

    /**
     * View mode
     * @return {string}
     */
    viewMode() {
      return this.block?.properties?.viewMode || 'icon';
    },

    /**
     * Checks if the block has a field property.
     *
     * @returns {boolean} True if the block has a field property, otherwise false.
     */
    hasField() {
      return !!this.block?.properties?.field;
    },
  },
  methods: {
    /**
     * Get icon
     * @param item
     * @return {string|undefined}
     */
    getIcon(item) {
      // If icon mode return icon
      if (this.viewMode === 'icon') {
        return typeof item.icon === 'string' ? item.icon : undefined;
      }

      // Get active image
      const activeImage = item.activeImage?.source_url || undefined;

      // If active tab and active image
      if (item.value === this.tabValue && activeImage) {
        return `img:${this.renderer.a2u.assetPath(activeImage)}`;
      }

      // Get image
      const image = item.image?.source_url || undefined;

      // Return image
      return image ? `img:${this.renderer.a2u.assetPath(image)}` : undefined;
    },

    /**
     * Click button
     */
    clickButton(val) {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, false, val)
    },

    /**
     * Handles the click event on a tab.
     * If the component has a field, the function returns early.
     * Otherwise, it triggers the clickButton method with the provided value.
     *
     * @param {string} val - The value of the clicked tab.
     */
    onClickTab(val) {
      if (this.hasField) {
        return;
      }

      this.clickButton(val);
    },
  },

  watch: {
    /**
     * Watcher for tabValue.
     * Updates the teleport transition direction based on the index of the new and old tab values.
     *
     * @param {string} newVal - The new tab value.
     * @param {string} oldVal - The old tab value.
     */
    tabValue(newVal, oldVal) {
      const newIdx = this.finalItems.findIndex((item) => item.value === newVal);
      const oldIdx = this.finalItems.findIndex((item) => item.value === oldVal);

      this.getStorage('fragment').set('teleportTransition', newIdx > oldIdx ? 'left' : 'right');
    },
  },
}

</script>

<style lang="scss">
.navigation-panel {

  .q-tabs--vertical {
    .q-tab {
      justify-content: start;

      label {
        margin-left:5px;
      }
    }
  }

  .q-tab {
    flex-grow: 1;
    padding: 0 10px;

    .q-tab__content {
      padding-top:10px;
    }
  }
}
</style>
