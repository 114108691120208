<template>
  <ab-flow-base-cmp :block="block" class="column teleport-editor-cmp relative-position" :class="classesString" :style="stylesString" v-if="currentPage?.id">
    <transition
      :name="transitionName"
      @before-enter="transitionBeforeEnter"
      @after-enter="transitionAfterEnter"
    >
      <div
        class="teleport-holder"
        :class="{ absolute: transitionActive }"
        :key="updateKey"
      >
        <diagram-component-editor-cmp v-if="currentPage?.type === 'DiagramComponent'" :ref="currentPage.id" :block="currentPage" :start-event="currentStartEvent"/>
        <fragment-editor-cmp v-else-if="currentPage.id" :block="currentPage" :ref="currentPage.id" />
      </div>
    </transition>
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import FragmentEditorCmp from "../../Containers/Fragment/FragmentEditorCmp.vue";
import DiagramComponentEditorCmp from "../../Logic/DiagramComponent/DiagramComponentEditorCmp.vue";

export default {
  components: {DiagramComponentEditorCmp, FragmentEditorCmp, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "TeleportEditorCmp",
  data: () => ({
    updateKey: 0,
    currentPage: false,
    //currentPageParams: {},
    currentStartEvent: "start",
    currentSlideBlockId: false,
    transitionActive: false,
  }),

  /**
   * On create
   */
  created() {

    // Add watcher
    this.$watch("currentActivePage", () => {
      if(this.currentActivePage) {
        // Get page node
        const pNode = this.renderer.a2u.blocks[this.currentActivePage?.blockId]?.node

        // Check if this.block.properties?.activeFrame is variable
        if (this.block.properties?.activeFrame?.valueType === 'variable' && pNode) {
          this.setValue(this.block.properties?.activeFrame, pNode?.title)
        }

        // Navigate
        this.navigate(this.currentActivePage)
      }
    }, {immediate: true, deep: true})

    // Register event
    //this.parentDiagram.registerHandler(this.block.id, 'navigate', this.navigate)
    //this.parentDiagram.registerHandler(this.block.id, 'updateState', this.updateState)

    // Watch for current fragment changes
    this.$watch("currentActiveFragment", () => {
      this.navigateByName(this.currentActiveFragment)
    }, {immediate: true})
  },

  /**
   * On destroy
   */
  beforeUnmount() {

    // Unregister event
    //AbEventManager.unregisterEvent(`${this.block.id}-navigate`, this.navigate)
    this.parentDiagram.unregisterHandler(this.block.id, 'navigate')
  },

  computed: {

    /**
     * Current active fragment
     * @return {*}
     */
    currentActiveFragment() {
      return this.getValue(this.block.properties?.activeFrame);
    },

    /**
     * Current page
     */
    currentActivePage() {
      return this.currentDiagram.getStorage("diagram").get(`teleports.${this.block.id}`);
    },

    /**
     * Component key
     * @return {string}
     */
    currentKey() {
      return JSON.stringify({page: this.currentPage.id, event: this.currentStartEvent, params: this.currentPageParams})
    },

    /**
     * Current page params
     * @return {{}}
     */
    currentPageParams() {
      return this.getArguments(this.currentPage.id);
    },

    /**
     * Gets the direction of the transition.
     * @returns {string} The direction of the transition, defaulting to 'left'.
     */
    transitionDirection() {
      return this.getStorage('fragment').get('teleportTransition', 'left') || 'left';
    },

    /**
     * Gets the name of the transition based on the direction.
     * @returns {string} The name of the transition.
     */
    transitionName() {
      return `q-transition--slide-${this.transitionDirection}`;
    },
  },

  methods: {

    /**
     * Update state
     */
    /*updateState() {
      // Update current block params
      this.navigate(this.currentActivePage);
    },*/

    /**
     * Navigate
     * @param data
     */
    navigate({blockId, event}) {
      this.currentPage = this.renderer.a2u.blocks[blockId]?.node
      //this.currentPageParams = this.getArguments(this.currentPage.id);
      this.currentStartEvent = event || "start";
      this.updateKey++
    },

    /**
     * Navigate by name
     * @param name
     */
    navigateByName(name) {

      // If name is empty - return
      if (!name) return

      // Links
      const links = this.renderer.a2u.links.outgoing[this.block.id]

      // Process each link
      if (links?.children) for (const sl of links.children) {

        // Get block from application by id
        const block = this.renderer.a2u.blocks[sl.target]?.node

        // If block exists - push to result
        if (block && (name && block.title === name)) {
          this.currentDiagram.getStorage("diagram").set(`teleports.${this.block.id}`, {blockId: block.id});
          break
        }
      }
    },

    /**
     * Handles actions to be performed before the transition enters.
     * Adds the 'overflow-hidden' class to the body to prevent scrolling.
     */
    transitionBeforeEnter() {
      document.body.classList.add('overflow-hidden');
      this.transitionActive = true;
    },

    /**
     * Handles actions to be performed after the transition enters.
     * Removes the 'overflow-hidden' class from the body to allow scrolling.
     */
    transitionAfterEnter() {
      document.body.classList.remove('overflow-hidden');
      this.transitionActive = false;
    },
  },
}

</script>

<style lang="scss">
.teleport-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
