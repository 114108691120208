import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class InAppPurchaseProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // get product id
        const productId = this.context.getValue(this.block?.properties?.product);

        // Check if product id is set
        if(!productId) {
            console.error("Product id is not set");

            // Log error
            this.logError(new Error('Product id is not set'));

            return this.processOutgoingLinks(this.context, this.block.id, false, "error");
        }

        const targetPlatform = this.context.renderer.a2u.source.modules[this.context.renderer.a2u.getModuleId()]?.type === 'web' ? 'web' : 'mobile';

        // Debug list of goods for web environment
        if(this.context.renderer.a2u.getPlatform() === "web" && targetPlatform !== 'web') {
            return this.debugResponse({success: {}, cancel: {}, error: {}}, `In app purchase: ${productId}`)
        }

        // Show loading
        this.app.$q.loading.show();

        // Disallow restore event
        this.context.renderer.a2u.allowRestore(false);

        // Get ad instance
        const iapManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

        // Get checkout mode
        // const checkoutMode = this.block?.properties?.checkoutMode || 'hosted';
        const checkoutMode = 'embedded';

        // Get products list
        iapManager.purchase(productId, checkoutMode).then(async (res) => {
            await this.postPurchase(targetPlatform, productId, res);

            console.log("Purchase result", res);
            return this.processOutgoingLinks(this.context, this.block.id, false, "success");
        }).catch(err => {
            console.error("Purchase error", err);

            // Log error
            if (err?.message !== "cancel") {
                this.logError(err);
            }

            return this.processOutgoingLinks(this.context, this.block.id, false, err?.message !==  "cancel" ? "error" : "cancel");
        }).finally(() => {
            // Hide loading
            this.app.$q.loading.hide();

            // Allow restore event
            this.context.renderer.a2u.allowRestore(true);
        });
    }

    /**
     * Handles post-purchase processing, including saving user purchase relations, emitting marketing events, and synchronizing purchases.
     *
     * @async
     * @param {string} targetPlatform - The platform on which the purchase was made ('web' or 'mobile').
     * @param {string} productId - The ID of the purchased product.
     * @param {Object} res - The response object from the purchase.
     */
    async postPurchase(targetPlatform, productId, res) {
        try {
            // Get purchase token
            const purchaseToken = res?.receipt?.purchasedProduct?.purchaseToken || null;

            // Save user relation with purchase
            if (purchaseToken) {
                const purchasePrice = parseInt(res?.receipt?.productDetails?.priceAmountMicros || 0) / 1000000;
                const currencyCode = res?.receipt?.productDetails?.priceCurrencyCode || 'USD';

                await this.context.renderer.a2u.analytics.saveUserRelationWithPurchase({
                    purchaseToken,
                    purchasePrice,
                    currencyCode,
                    productId,
                    diagramId: this.context?.currentDiagram?.diagramObj?.id || null,
                });
            }

            // Emit marketing event for web platform
            if (targetPlatform === 'web' && res?.price && res?.currency) {
                this.emitMarketingEvent(res.price, res.currency);
            }

            // Sync purchase for mobile platform
            if (purchaseToken && targetPlatform === 'mobile') {
                await this.syncPurchase(
                  productId,
                  purchaseToken,
                  res?.receipt?.purchasedProduct?.packageName,
                  res?.receipt?.purchasedProduct?.productId,
                  res?.receipt?.productDetails?.productType,
                );
            }
        } catch (e) {
            console.error('Error in postPurchase:', e);

            // Log error
            this.logError(e);
        }
    }

    /**
     * Emits a marketing event with the specified price and currency.
     * This method attempts to retrieve an instance of the MarketingPixelsPlugin from the device.
     * If the plugin is found, it emits a 'Purchase' event with the given price and currency as its details.
     * If the plugin is not found, the method exits without emitting the event.
     *
     * @param {number} price - The price associated with the marketing event.
     * @param {string} currency - The currency of the price.
     */
    emitMarketingEvent(price, currency) {
        try {
            if (this.app?.renderer?.a2u?.runMode !== "release") {
                return;
            }

            const plugin = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("MarketingPixelsPlugin");

            if(!plugin) {
                return;
            }

            plugin.emit('Purchase', {
                value: price,
                currency,
            });
        } catch (e) {
            console.error('Error emitting marketing event:', e);

            // Log error
            this.logError(e);
        }
    }

    /**
     * Synchronizes the purchase with the server.
     * This method finds a server module that has in-app purchase synchronization enabled,
     * constructs the endpoint URL, and sends the purchase details to the server for processing.
     *
     * @async
     * @param {string} productId - The ID of the purchased product.
     * @param {string} purchaseToken - The purchase token received from the in-app purchase.
     * @param {string} packageName - The package name of the app on the device.
     * @param {string} platformProductId - The ID of the product on the platform (e.g., Google Play).
     * @param {string} type - The type of the purchase ('subs' for subscription, 'inapp' for one-time purchase).
     */
    async syncPurchase(productId, purchaseToken, packageName, platformProductId, type) {
        try {
            // Find a server module with IAP synchronization enabled
            const syncModule = Object.values(this.context.renderer.a2u.source.modules)
              .find((m) => m.type === 'server' && m?.iapSyncEnable);

            // Extract the endpoint URL from the found module
            const endpoint = syncModule?.endpointUrl;

            // If no endpoint is found, exit the function
            if (!endpoint) {
                return;
            }

            // Retrieve the current module ID
            const moduleId = this.context.renderer.a2u.getModuleId();

            // Call the server's IAP synchronization endpoint with the purchase details
            await this.app.app.client.call(
              `${endpoint}/iap-sync`,
              'saveReceipt',
              moduleId,
              productId,
              purchaseToken,
              packageName,
              platformProductId,
              type === 'subs' ? 'subscription' : 'product',
            );
        } catch (e) {
            console.error('Error in syncPurchase:', e);

            // Log error
            this.logError(e);
        }
    }
}
